import React from "react"
import parse from "html-react-parser"

import Buttons from "@components/buttons"
import "./style.scss"

const TextColumns = ({ sectionData }) => {
  const { heading, leftColumn, rightColumn, links, sectionSelectors } =
    sectionData

  return (
    <section
      className={`s-TextColumns ${
        sectionSelectors.sectionClasses ? sectionSelectors.sectionClasses : ""
      }`}
      id={sectionSelectors.sectionId}
    >
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="inner">
              {heading ? <h3 className="h1">{heading}</h3> : null}
              {heading ? <hr /> : null}

              <div className="columns">
                <div className="column is-6">
                  {leftColumn ? parse(leftColumn) : null}
                </div>
                <div className="column is-6">
                  {rightColumn ? parse(rightColumn) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextColumns
