import React from "react"
import parse from "html-react-parser"

import Buttons from "@components/buttons"
import "./style.scss"

const LocationListing = ({ sectionData }) => {
  const { heading, image, bodyText, locations, links, sectionSelectors } =
    sectionData

  return (
    <section
      className={`s-LocationListing ${
        sectionSelectors.sectionClasses ? sectionSelectors.sectionClasses : ""
      }`}
      id={sectionSelectors.sectionId}
    >
      {image ? (
        <div
          className="image column is-4"
          style={{
            padding: 0,
          }}
        >
          <img src={image.localFile.publicURL} alt={image.altText} />
        </div>
      ) : null}
      <div className="container">
        <div className="columns">
          <div className="column is-6 is-offset-5">
            {heading ? <h3>{heading}</h3> : null}

            {heading ? <hr /> : null}

            {bodyText ? (
              <div className="body-text">{parse(bodyText)}</div>
            ) : null}

            {links ? <Buttons links={links} /> : null}

            {locations ? (
              <div className="locations">
                <div className="columns is-multiline">
                  {locations.map((location, index) => {
                    return (
                      <div className="column is-6" key={`location_${index}`}>
                        <div className="location">
                          <h4 className="name">{location.title}</h4>

                          {location.address ? (
                            <p className="address">{parse(location.address)}</p>
                          ) : null}

                          {location.phoneNumber ? (
                            <ul>
                              {location.phoneNumber.map(
                                (phoneNumber, index) => {
                                  return (
                                    <li
                                      key={`phoneNumber_${index}`}
                                    >{`${phoneNumber.label} ${phoneNumber.phoneNumber}`}</li>
                                  )
                                }
                              )}
                            </ul>
                          ) : null}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default LocationListing
