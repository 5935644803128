import React, { useRef, useEffect, useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import HeightMatchingGroup from "@components/utils/match-height"
import VisibilitySensor from "react-visibility-sensor-v2"
import gsap, { Power2 } from "gsap"

import FadeLink from "@components/fade-link"

import "keen-slider/keen-slider.min.css"

const ArrowLeft = ({ disabled, onClick }) => {
  const disabledClass = disabled ? "arrow--disabled" : ""
  return (
    <button onClick={onClick} className={`arrow arrow--left ${disabledClass}`}>
      <svg
        width="15"
        height="24"
        viewBox="0 0 15 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4 1.425L12.825 0L0 12L12.825 24L14.4 22.575L3.15 12L14.4 1.425Z"
          fill="#333132"
        />
      </svg>
      <span className="is-sr-only">{`Previous slide`}</span>
    </button>
  )
}

const ArrowRight = ({ disabled, onClick }) => {
  const disabledClass = disabled ? "arrow--disabled" : ""
  return (
    <button onClick={onClick} className={`arrow arrow--right ${disabledClass}`}>
      <svg
        width="15"
        height="24"
        viewBox="0 0 15 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1.425L1.575 0L14.4 12L1.575 24L0 22.575L11.325 12L0 1.425Z"
          fill="#333132"
        />
      </svg>
      <span className="is-sr-only">{`Next slide`}</span>
    </button>
  )
}

const Slider = ({ slides }) => {
  const tl = useRef()
  const dotsRef = useRef()
  const [scrollbarScrubber] = useRef()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderMounted, setSliderMounted] = useState(false)
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 3,
    mode: "free-snap",
    spacing: 25,
    centered: false,
    loop: false,
    breakpoints: {
      "(min-width: 720px) and (max-width: 1200px)": {
        slidesPerView: 2,
      },
      "(min-width: 0px) and (max-width: 720px)": {
        slidesPerView: 1,
      },
    },
    mounted() {
      setSliderMounted(true)
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })

  useEffect(() => {
    if (!tl.current && sliderMounted) {
      tl.current = gsap.timeline({
        paused: true,
      })
    }
  }, [sliderMounted])

  useEffect(() => {
    if (tl.current && sliderMounted) {
      let timeline = tl.current
      let slides = sliderRef.current.getElementsByClassName("slide-card")

      timeline
        .fromTo(
          slides,
          0.5,
          {
            x: `30%`,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            stagger: 0.075,
            ease: Power2.easeInOut,
          }
        )
        .fromTo(
          dotsRef.current,
          0.2,
          {
            opacity: 0,
          },
          {
            opacity: 1,
          }
        )
    }
  }, [sliderMounted])

  const onEnterViewport = () => {
    tl.current?.play()
  }

  const renderSlide = slide => {
    const { carouselImage, locations, ageGroup } = slide.programDetails
    return (
      <div key={slide.id} className="keen-slider__slide">
        <div className="slide-card program-slide">
          <div className="image">
            {carouselImage ? (
              <img
                src={carouselImage.localFile.publicURL}
                alt={carouselImage.altText}
              />
            ) : null}
          </div>

          <div className="details">
            <h3 className="title">{slide.title}</h3>
            <hr />

            {ageGroup ? (
              <div className="age-groups">
                <h6>{`Servicing`}</h6>
                <span className="age-group">
                  <ul>
                    {ageGroup
                      ? ageGroup.map((ageGroup, index) => {
                          return (
                            <li key={`ageGroup_${index}`}>
                              {ageGroup.ageGroup}
                            </li>
                          )
                        })
                      : null}
                  </ul>
                </span>
              </div>
            ) : null}
            {locations ? (
              <div className="service-locations">
                <h6>{`Service Locations`}</h6>
                <ul>
                  {locations.map((location, index) => (
                    <li key={`location_${index}`} className="location">
                      {location.name}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          <FadeLink to={slide.uri} className={`button program-button`}>
            <span>{`Learn more`}</span>
            <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              alt="Forward Arrow"
            >
              <path
                d="M25.0607 13.0607C25.6464 12.4749 25.6464 11.5251 25.0607 10.9393L15.5147 1.3934C14.9289 0.80761 13.9792 0.80761 13.3934 1.3934C12.8076 1.97918 12.8076 2.92893 13.3934 3.51472L21.8787 12L13.3934 20.4853C12.8076 21.0711 12.8076 22.0208 13.3934 22.6066C13.9792 23.1924 14.9289 23.1924 15.5147 22.6066L25.0607 13.0607ZM1.31134e-07 13.5L24 13.5L24 10.5L-1.31134e-07 10.5L1.31134e-07 13.5Z"
                fill="white"
              />
            </svg>
          </FadeLink>
        </div>
      </div>
    )
  }

  return (
    <VisibilitySensor
      onChange={onEnterViewport}
      partialVisibility={true}
      delayedCall={true}
    >
      <div className="slider-container">
        <div className="container">
          <HeightMatchingGroup tagName="div" selector=".details">
            <HeightMatchingGroup selector=".title">
              <div ref={sliderRef} className="keen-slider">
                {slides ? slides.map(slide => renderSlide(slide)) : null}
              </div>
            </HeightMatchingGroup>
          </HeightMatchingGroup>
        </div>

        <div className="container has-text-right has-text-centered-mobile">
          {slider && (
            <div className="dots" ref={dotsRef}>
              <ArrowLeft
                onClick={e => e.stopPropagation() || slider.prev()}
                disabled={currentSlide === 0}
              />
              <ArrowRight
                onClick={e => e.stopPropagation() || slider.next()}
                disabled={
                  slider.details().slidesPerView ===
                  slider.details().size - slider.details().relativeSlide
                }
              />
            </div>
          )}
        </div>
      </div>
    </VisibilitySensor>
  )
}

export default Slider
