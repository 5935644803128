import React from "react"
import _ from "lodash"
import parse from "html-react-parser"

import "./style.scss"
import Slider from "./slider"

const ProgramListing = ({ sectionData }) => {
  const {
    backgroundColor,
    heading,
    subheading,
    bodyText,
    programCategory,
    sectionSelectors,
  } = sectionData

  const { programs } = programCategory.programs

  // Sort programs by menuOrder
  let sortedPrograms = _.sortBy(programs, [
    p => {
      return p.menuOrder
    },
  ])

  return (
    <section
      className={`s-ProgramListing ${backgroundColor} ${
        sectionSelectors.sectionClasses ? sectionSelectors.sectionClasses : ""
      }`}
      id={sectionSelectors.sectionId}
    >
      <div className="container">
        <div className="columns">
          <div className="column is-5 is-offset-1">
            {subheading ? <span className="h6">{subheading}</span> : null}
            {heading ? <h3>{heading}</h3> : null}

            {heading || subheading ? <hr /> : null}

            {bodyText ? (
              <div className="body-text">{parse(bodyText)}</div>
            ) : null}
          </div>
        </div>
      </div>

      <Slider slides={sortedPrograms} />
    </section>
  )
}

export default ProgramListing
