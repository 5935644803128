import React from "react"

import LocationListing from "@components/location-listing"
import TextWithImage from "@components/text-with-image"
import ProgramListing from "@components/program-listing"
import ResourceListing from "@components/resource-listing"
import TextColumns from "@components/text-columns"

const Blocks = ({ blocks }) => {
  const renderBlock = (block, index) => {
    const key = `${block.fieldGroupName}_${index}`
    switch (block.fieldGroupName) {
      case "Page_Blocks_Sections_LocationListing":
        return <LocationListing key={key} sectionData={block} />
      case "Page_Blocks_Sections_TextWithImage":
        return <TextWithImage key={key} sectionData={block} />
      case "Page_Blocks_Sections_ProgramListing":
        return <ProgramListing key={key} sectionData={block} />
      case "Page_Blocks_Sections_ResourceListing":
        return <ResourceListing key={key} sectionData={block} />
      case "Page_Blocks_Sections_TextColumns":
        return <TextColumns key={key} sectionData={block} />
      default:
        return null
    }
  }

  return blocks
    ? blocks.map((block, index) =>
        block.hideSection !== true ? renderBlock(block, index) : null
      )
    : `No blocks found.`
}

export default Blocks
