import React from "react"
import parse from "html-react-parser"

import Buttons from "@components/buttons"
import "./style.scss"

const TextWithImage = ({ sectionData }) => {
  const {
    alignment,
    style,
    heading,
    subheading,
    image,
    bodyText,
    links,
    sectionSelectors,
  } = sectionData

  const renderColumn = columnType => {
    switch (columnType) {
      case "image":
        return image ? <img src={image.localFile.publicURL} alt="" /> : null

      case "text":
        return (
          <div>
            {subheading ? <span className="h6">{subheading}</span> : null}
            {heading ? <h1>{heading}</h1> : null}

            {heading || subheading ? <hr /> : null}

            {bodyText ? (
              <div className="body-text">{parse(bodyText)}</div>
            ) : null}

            {links ? <Buttons links={links} /> : null}
          </div>
        )
      default:
        return null
    }
  }

  return (
    <section
      className={`s-TextWithImage ${alignment} ${style} ${
        sectionSelectors.sectionClasses ? sectionSelectors.sectionClasses : ""
      }`}
      id={sectionSelectors.sectionId}
    >
      {style === "full-width" ? (
        <div className="column is-6 image-column">{renderColumn("image")}</div>
      ) : null}
      <div className="container">
        <div
          className={`columns is-flex-tablet ${
            alignment === "img-right" ? `reversed` : ``
          }`}
        >
          <div className="column is-6">
            {style !== "full-width" ? renderColumn("image") : parse(`&nbsp;`)}
          </div>
          <div className="column is-5 is-offset-1">{renderColumn("text")}</div>
        </div>
      </div>
    </section>
  )
}

export default TextWithImage
