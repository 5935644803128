import React from "react"
import parse from "html-react-parser"
import HeightMatchingGroup from "@components/utils/match-height"

import "./style.scss"

const ResourceListing = ({ sectionData }) => {
  const { heading, bodyText, resources, sectionSelectors } = sectionData

  return (
    <section
      className={`s-ResourceListing ${
        sectionSelectors.sectionClasses ? sectionSelectors.sectionClasses : ""
      }`}
      id={sectionSelectors.sectionId}
    >
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            {heading ? <h1>{heading}</h1> : null}

            {heading ? <hr /> : null}

            {bodyText ? (
              <div className="body-text">{parse(bodyText)}</div>
            ) : null}
          </div>
        </div>
      </div>

      {resources
        ? resources.map((resource, index) => {
            const { resources, heading } = resource
            return (
              <HeightMatchingGroup
                tagName="a"
                selector=".resource"
                key={`resource_category_${index}`}
              >
                <div className="container">
                  {heading ? (
                    <div>
                      <h2 className="h3">{heading}</h2>
                      <hr className="secondary" />
                    </div>
                  ) : null}
                  <div className="columns is-multiline">
                    {resources
                      ? resources.map((resource, index) => {
                          const { title, file } = resource
                          return (
                            <div
                              key={`resource_${index}`}
                              className="column is-4-desktop is-6-tablet is-12-mobile is-3-widescreen"
                            >
                              <a
                                target="_blank"
                                rel="noreferrer"
                                className="resource"
                                href={file.localFile.publicURL}
                              >
                                {title}

                                <svg
                                  alt="External link icon"
                                  width="17"
                                  height="22"
                                  viewBox="0 0 17 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0)">
                                    <path
                                      d="M3.30862 15.0684L1.82291 13.5827L10.8456 4.55999L2.48 4.46149L2.48899 2.38149L14.433 2.45826L14.5098 14.4023L12.3851 14.411L12.3313 6.04571L3.30862 15.0684Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M17 19.8943H0V22H17V19.8943Z"
                                      fill="black"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect
                                        width="17"
                                        height="22"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </a>
                            </div>
                          )
                        })
                      : null}
                  </div>
                </div>
              </HeightMatchingGroup>
            )
          })
        : null}
    </section>
  )
}

export default ResourceListing
